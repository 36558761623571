import PrivateLayout from "../../layouts/PrivateLayout/PrivateLayout";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import useCoachingTemplatesStore from "../../store/useCoachingTemplatesStore";

import Chat from "./Chat/Chat";
import { Col, Row } from "antd";

export default function ScenarioChat() {
  const id = useParams().id;

  const { templates } = useCoachingTemplatesStore((state) => state);

  const [scenario, setScenario] = useState(
    templates.find((e) => e?._id === id) || {}
  );

  useEffect(() => {
    setScenario(templates.find((e) => e._id === id));
  }, [templates]);

  return (
    <PrivateLayout fullWidth={true}>
      <Row justify={"center"}>
        <Col xs={24} sm={24} md={20} lg={20} xl={20} xxl={20}>
          <Chat chat_data={scenario} />
        </Col>
      </Row>
    </PrivateLayout>
  );
}
