import { useEffect } from "react";

import { Route, Routes } from "react-router-dom";
import { App, ConfigProvider, notification, theme } from "antd";

import Landing from "./screens/Landing/Landing";
import Login from "./screens/Login/Login";
import Home from "./screens/Home/Home";
import Scenario from "./screens/Scenario/Scenario";
import Admin from "./screens/Admin/Admin";
import Profile from "./screens/Profile/Profile";
import Users from "./screens/Admin/Users/Users";
import ScenarioSummary from "./screens/Scenario/ScenarioSummary";
import ScenarioChat from "./screens/Scenario/ScenarioChat";
import ForgetPass from "./screens/ForgetPass/ForgetPass";
import useAppStore from "./store/useAppStore";
import SystemScenarios from "./screens/Admin/SystemScenarios/SystemScenarios";

export default function AppRoutes() {
  const { setNotification } = useAppStore((state) => state);
  const [notifyApi, contextHolder] = notification.useNotification();
  useEffect(() => {
    setNotification(notifyApi);
  }, []);
  return (
    <ConfigProvider
      theme={{
        // 1. Use dark algorithm
        algorithm: theme.darkAlgorithm,
        token: {
          colorPrimary: "#D71E1E",
          colorLink: "#D71E1E",
          colorLinkActive: "#D71E1E",
          colorLinkHover: "#D71E1E",
          colorText: "#FFFFFF",
          borderRadius: 20,
          controlHeight: 53,
          fontFamily: "Inter",
        },
      }}
    >
      {contextHolder}
      <App>
        <Routes>
          <Route path="/" element={<Landing />} />
          <Route path="/login" element={<Login />} />
          <Route path="/home" element={<Home />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/scenario" element={<Scenario />} />
          <Route path="/scenario/summary/:id" element={<ScenarioSummary />} />
          <Route path="/scenario/chat/:id" element={<ScenarioChat />} />
          <Route path="/resetpassword/:token" element={<ForgetPass />} />
          <Route path="/scenario/:id" element={<Scenario />} />

          {/* Admin routes */}
          <Route path="/admin" element={<Admin />} />
          <Route path="/admin/users" element={<Users />} />
          <Route path="/admin/system/scenarios" element={<SystemScenarios />} />

        </Routes>
      </App>
    </ConfigProvider>
  );
}
