import { Card, Button, Table, Space, Popconfirm, Modal } from "antd";
import AdminLayout from "../../../layouts/AdminLayout/AdminLayout";

import { useEffect, useState } from "react";

import { FaEdit, FaPlus, FaTrash } from "react-icons/fa";

import useSystemScenariosApi from "../../../api/useSystemScenariosApi";
import ScenarioForm from "../../../forms/ScenarioForm/ScenarioForm";

export default function SystemScenarios() {
  const { fetchAllSystemScenarios, deleteScenario } = useSystemScenariosApi();

  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);

  const [systemScenarios, setSystemScenarios] = useState([]);

  const getScenarios = async () => {
    try {
      setLoading(true);
      let {
        data: { list },
      } = await fetchAllSystemScenarios();

      setSystemScenarios(list);

      setLoading(false);
    } catch (error) {
      console.log("error ", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    getScenarios();
  }, []);

  const columns = [
    { title: "Title", key: "title", dataIndex: "title", width: 300 },
    {
      title: "Job Title",
      key: "jobTitle",
      dataIndex: "jobTitle",
      width: 300,
      render: (value) => (value && value !== "" ? value : "N/A"),
    },
    {
      title: "Scenario",
      key: "scenarioSpecifics",
      dataIndex: "scenarioSpecifics",
      render: (value, row) => (
        <div style={{ minWidth: 200, height: 100, overflowY: "auto" }}>
          {value}
        </div>
      ),
    },
    {
      title: "Actions",
      key: "actions",
      dataIndex: "_id",
      render: (value, row) => (
        <Space>
          <Button
            icon={<FaEdit />}
            size="small"
            onClick={() => {
              setSelectedRecord(row);
              setIsModalOpen(true);
            }}
          >
            Edit
          </Button>
          <Popconfirm
            onCancel={() => {}}
            onConfirm={async () => {
              try {
                setLoading(true);
                await deleteScenario(row._id);
              } catch (ex) {
                console.log({ ex });
              } finally {
                setLoading(false);
                getScenarios();
              }
            }}
            title="Are you sure?"
            description="Are you sure you want to delete this record?"
          >
            <Button type="primary" icon={<FaTrash />} size="small">
              Delete
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <AdminLayout>
      <Card
        title={"Administration - Scenarios"}
        style={{
          margin: 10,
          width: "calc(100% - 20px)",
          minHeight: "calc(100vh - 260px)",
          background: "transparent",
        }}
        extra={[
          <Button
            size="small"
            icon={<FaPlus />}
            key={"add-btn"}
            type="primary"
            onClick={() => {
              setSelectedRecord(null);
              setIsModalOpen(true);
            }}
          >
            Add
          </Button>,
        ]}
      >
        <div style={{ width: "100%", overflowX: "auto" }}>
          <Table
            loading={loading}
            columns={columns}
            dataSource={systemScenarios}
          />
        </div>
      </Card>

      <Modal
        footer={false}
        open={isModalOpen}
        onCancel={async () => {
          setIsModalOpen(false);
          setSelectedRecord(null);
          await getScenarios();
        }}
        onSaveCallback={async () => {
          setIsModalOpen(false);
          setSelectedRecord(null);
          await getScenarios();
        }}
        title={selectedRecord ? "Edit Scenario" : "Create New Scenario"}
        width={"50%"}
      >
        <ScenarioForm
          isSystemScenario={true}
          record={selectedRecord}
          onSave={(data) => {
            getScenarios();
            setIsModalOpen(false);
            setSelectedRecord(null);
          }}
        />
      </Modal>
    </AdminLayout>
  );
}
