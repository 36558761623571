import React, { useState } from "react";
import { Button, Form, Input, Modal } from "antd";

import { FaUser } from "react-icons/fa";
import { IoMdLock } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import useAppStore from "../../store/useAppStore";
import useApi from "../../api/useApi";

export default function LoginForm() {
  const navigate = useNavigate();
  const { setAuthenticatedUser, notificationApi } = useAppStore(
    (state) => state
  );
  const setJwt = useAppStore((state) => state.setJwt);
  const [isFPassModalOpen, setIsFPassModalOpen] = useState(false);
  const [loader, setLoader] = useState(false);
  let { api } = useApi();

  const onFinish = async (values) => {
    // verify user login
    try {
      setLoader(true);
      let { data = {} } = await api.post("/api/auth/login", values);
      setAuthenticatedUser(data.user);
      setJwt(data.jwt);
      localStorage.setItem("user", JSON.stringify(data.user)); // is this needed?
      localStorage.setItem("token", data.jwt); // is this needed?
      notificationApi["success"]({
        message: "Success",
      });
      navigate("/home");
    } catch (error) {
      notificationApi["error"]({
        message: "Invalid Credential",
      });
      console.log("error ", error);
    }
    setLoader(false);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const handleForgetPassClick = async (values) => {
    setLoader(true);
    try {
      await api.post("/api/auth/forgetpassword", values);
      setIsFPassModalOpen(false);
      notificationApi["info"]({
        message: "Email sent!",
      });
    } catch (error) {
      setIsFPassModalOpen(false);
      notificationApi["error"]({
        message: "No user found with this email",
      });
      console.log("error ", error);
    }
    setLoader(false);
  };

  const renderForgetPasswordPopup = () => {
    return (
      <Modal
        footer={false}
        open={isFPassModalOpen}
        onCancel={() => {
          setIsFPassModalOpen(false);
        }}
        title={"Forget Password"}
      >
        <Form
          style={{ width: "100%" }}
          name="reset=pass-form"
          onFinish={handleForgetPassClick}
          autoComplete="off"
          layout="vertical"
        >
          <Form.Item
            name="email"
            rules={[
              {
                required: true,
                message: "Please input your email!",
                type: "email",
              },
            ]}
          >
            <Input
              prefix={<FaUser style={{ color: "#D71E1E" }} />}
              placeholder="Enter your Email"
            />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" block loading={loader}>
              Send Forget Request
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    );
  };

  return (
    <Form
      style={{ width: "100%" }}
      name="login-form"
      initialValues={{
        remember: true,
      }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
      layout="vertical"
    >
      <Form.Item
        name="email"
        rules={[
          {
            required: true,
            message: "Please input your email!",
            type: "email",
          },
        ]}
      >
        <Input
          prefix={<FaUser style={{ color: "#D71E1E" }} />}
          placeholder="Enter your Email"
        />
      </Form.Item>

      <Form.Item
        name="password"
        rules={[
          {
            required: true,
            message: "Please input your password!",
          },
        ]}
        style={{ marginBottom: 0 }}
      >
        <Input.Password
          prefix={<IoMdLock style={{ color: "#D71E1E" }} />}
          placeholder="Enter your Password"
        />
      </Form.Item>

      <div style={{ textAlign: "right", marginTop: 0, marginBottom: 50 }}>
        <Button
          type="link"
          onClick={() => {
            setIsFPassModalOpen(true);
          }}
        >
          Forgot Password?
        </Button>
      </div>

      <Form.Item>
        <Button type="primary" htmlType="submit" block loading={loader}>
          Login
        </Button>
      </Form.Item>
      {renderForgetPasswordPopup()}
    </Form>
  );
}
