import { Button, Flex } from "antd";
import PublicLayout from "../../layouts/PublicLayout/PublicLayout";

import BridgeLogoV3 from "../../assets/img/bridge-logo-v3.png";

import { useNavigate } from "react-router-dom";
import ForgetpassForm from "../../forms/LoginForm/ForgetpassForm ";

export default function ForgetPass() {
  const navigate = useNavigate();

  return (
    <PublicLayout>
      <Flex align="center" justify="center" vertical>
        <div>
          <Button
            type="link"
            onClick={() => {
              navigate("/");
            }}
            block
          >
            <img
              style={{ width: 500, marginTop: 75, marginBottom: 50 }}
              src={BridgeLogoV3}
              alt="bridge-logo-small"
            />
          </Button>
        </div>
        <div
          style={{
            marginTop: 10,
            fontSize: 16,
            fontWeight: "normal",
            width: 339,
            textAlign: "center",
          }}
        >
          <p>Please Enter Your new Password here</p>
        </div>
        <ForgetpassForm />
      </Flex>
    </PublicLayout>
  );
}
