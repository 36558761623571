import { Button } from "antd";
import { useEffect, useState } from "react";
import { FaUpload } from "react-icons/fa";

const Upload = ({ setImage, image }) => {
  const [loaded, setLoaded] = useState(false);
  const [uploadedImage, setUploadedImage] = useState(image || null);
  const [isDisabled, setIsDisabled] = useState(false);

  useEffect(() => {
    if (image) {
      setUploadedImage(image);
    }
  }, [image]);

  useEffect(() => {
    const uwScript = document.getElementById("uw");
    if (!loaded && !uwScript) {
      const script = document.createElement("script");
      script.setAttribute("async", "");
      script.setAttribute("id", "uw");
      script.src = "https://upload-widget.cloudinary.com/global/all.js";
      script.addEventListener("load", () => setLoaded(true));
      document.body.appendChild(script);
    }
  }, [loaded]);

  const processResults = (error, result) => {
    if (result.event === "close") {
      setIsDisabled(false);
    }
    if (result && result.event === "success") {
      const secureUrl = result.info.secure_url;
      
      setImage(secureUrl);
      setUploadedImage(secureUrl);
      setIsDisabled(false);
    }
    if (error) {
      setIsDisabled(false);
    }
  };
  const cloudName = "djxonosgl";
  const uploadPreset = "wkhvkoyl";

  const uploadWidget = () => {
    setIsDisabled(true);
    window.cloudinary.openUploadWidget(
      {
        cloudName,
        uploadPreset,
        sources: ["local", "url"],
        tags: ["myphotoalbum-react"],
        clientAllowedFormats: ["image"],
        resourceType: "image",
      },
      processResults
    );
  };
  return (
    <div style={{ display: "flex", justifyContent: "space-between" }}>
      <Button
        disabled={isDisabled}
        type="primary"
        icon={<FaUpload />}
        onClick={uploadWidget}
      >
        Click to Upload Image
      </Button>
      {uploadedImage ? (
        <img style={{ width: "100px" }} src={uploadedImage} alt="uploaded" />
      ) : null}
    </div>
  );
};

export default Upload;
