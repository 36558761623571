import axios from "axios";
import useAppStore from "../store/useAppStore";

export default function useApi(authenticated = false) {
  const jwt = useAppStore((state) => state.jwt);

  const {
    location: { hostname },
  } = window;

  const localhost = "http://localhost:8000";
  const staging = "https://bridgeapi.dfysaas.com";
  const production = "https://bridgeapi.bridgeco.ai";

  const apiUrl =
    hostname === "bridge.dfysaas.com"
      ? staging
      : hostname === "localhost"
      ? localhost
      : production;

  let api = axios.create({ baseURL: apiUrl });

  if (authenticated) {
    api = axios.create({
      baseURL: apiUrl,
    });

    api.defaults.headers = { Authorization: `Bearer ${jwt}` };
  }

  return { api };
}
