import { Button, Col, Flex, Layout, Row } from "antd";
import useAppStore from "../../store/useAppStore";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import BridgeLogoV3 from "../../assets/img/bridge-logo-v3.png";
import Background from "../../components/Background/Background";

const { Content } = Layout;

export default function PrivateLayout({ children, fullWidth }) {
  const authenticatedUser = useAppStore((state) => state.authenticatedUser);

  const jwt = useAppStore((state) => state.jwt);
  const navigate = useNavigate();

  useEffect(() => {
    if (!authenticatedUser && !jwt) {
      navigate("/login");
    }
  }, [jwt, authenticatedUser, navigate]);

  return (
    <Layout style={{ width: "100%", height: "100vh", overflowX: "hidden" }}>
      <Background>
        <Content
          style={{ width: "100%", height: "100vh", position: "relative" }}
        >
          <Row align={"top"} justify={"center"} gutter={[10, 10]}>
            <Col span={8}>
              <Flex align="center" justify="center" gap={30}>
                <div>
                  <Button
                    type="link"
                    onClick={() => {
                      navigate("/home");
                    }}
                  >
                    <img
                      style={{ width: 250, marginTop: 10, marginBottom: 10 }}
                      src={BridgeLogoV3}
                      alt="bridge-logo-horizontal"
                    />
                  </Button>
                </div>
              </Flex>
            </Col>
          </Row>
          <Row align={"top"} justify={"center"} gutter={[10, 10]}>
            <Col
              xs={fullWidth ? 24 : 24}
              sm={fullWidth ? 24 : 24}
              md={fullWidth ? 24 : 18}
              lg={fullWidth ? 24 : 16}
              xl={fullWidth ? 24 : 12}
              xxl={fullWidth ? 24 : 8}
            >
              {children}
            </Col>
          </Row>
        </Content>
      </Background>
    </Layout>
  );
}
