import { Form, Input } from "antd";

import UploadCloudnry from "../../../components/ImageUpload/Upload";

export default function RoleplayCharacter({
  form,
  index,
  setChars,
  chars,
  character,
}) {
  const setImage = (uri) => {
    let obj = chars;
    obj[index].photoUrl = uri;
    setChars([...obj]);
  };

  return (
    <>
      <Form.Item
        label="Roleplay Character(s)"
        name={"roleplayCharacters" + index}
        rules={[
          {
            required: true,
            message: "Please input your Roleplay Character!",
          },
        ]}
      >
        <Input.TextArea
          rows={5}
          onChange={({ target }) => {
            // local state is use to store data , then will be pushed to api
            let obj = chars;
            obj[index].description = target.value;
            setChars([...obj]);
          }}
          placeholder="In the roleplay scenario that you are creating, who is the character that you are interacting with. Tell us about them, their name, their character, their job titles, any physical descriptors that might be relevant (mood, state of mind, etc.)."
        />
      </Form.Item>

      <Form.Item>
        <UploadCloudnry setImage={setImage} image={chars[index].photoUrl} />
        {/* <Input
          styles={{ addonAfter: { padding: 0 } }}
          readOnly
          addonAfter={
            <Upload style={{ margin: 0, padding: 0 }}>
              <Button type="primary" icon={<FaUpload />}>
                Click to Upload
              </Button>
            </Upload>
          }
        /> */}
      </Form.Item>
    </>
  );
}
