import React from "react";
import { Button, Form, Input, Space, notification } from "antd";

import { FaBackward, FaSave } from "react-icons/fa";

import { useNavigate } from "react-router-dom";
import useAppStore from "../../store/useAppStore";
import useApi from "../../api/useApi";

export default function ProfileForm() {
  const { authenticatedUser, setAuthenticatedUser } = useAppStore(
    (state) => state
  );
  const [notify, contextHolder] = notification.useNotification();

  const navigate = useNavigate();

  let { api } = useApi(true);

  const [form] = Form.useForm();

  const onFinish = async (values) => {
    // Update user
    try {
      if (values.password) {
        if (values.password !== values.cpassword) {
          notify.open({
            message: "Password not match",
            description: "Password not match",
          });
          return;
        }
      }
      let { data } = await api.put("/api/users/", values);
      if (data.user) {
        setAuthenticatedUser(data.user);
        navigate("/home");
      }
    } catch (error) {
      console.log("error ", error);
    }
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  let intiData = { ...authenticatedUser, password: "" };
  return (
    <Form
      style={{ width: "100%" }}
      name="profile-form"
      initialValues={{ ...intiData }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
      layout="vertical"
      form={form}
    >
      <Form.Item
        label="Email"
        name="email"
        rules={[
          {
            required: true,
            message: "Please input your email!",
            type: "email",
          },
        ]}
      >
        <Input readOnly placeholder="Enter your Email" />
      </Form.Item>

      <Form.Item
        label="Name"
        name="name"
        rules={[
          {
            required: true,
            message: "Please input your name!",
          },
        ]}
      >
        <Input placeholder="Enter your Name" />
      </Form.Item>

      <Form.Item
        label="Company Name"
        name="companyName"
        rules={[
          {
            required: true,
            message: "Please input your company name!",
          },
        ]}
      >
        <Input placeholder="Enter your Company Name" />
      </Form.Item>

      <Form.Item
        label="Job Title"
        name="jobTitle"
        rules={[
          {
            required: true,
            message: "Please input your job title!",
          },
        ]}
      >
        <Input placeholder="Enter your Job Title" />
      </Form.Item>

      <Form.Item label="Password" name="password">
        <Input placeholder="Enter your password" />
      </Form.Item>

      <Form.Item label="Confirm password" name="cpassword">
        <Input placeholder="Enter your password" />
      </Form.Item>

      <Form.Item>
        <Space>
          <Button
            icon={<FaBackward />}
            onClick={() => {
              navigate("/home");
            }}
          >
            Cancel
          </Button>
          <Button icon={<FaSave />} type="primary" htmlType="submit">
            Save
          </Button>
        </Space>
      </Form.Item>
      {contextHolder}
    </Form>
  );
}
