import { Card, Button, Table, Flex, Space, Popconfirm, Modal } from "antd";
import AdminLayout from "../../../layouts/AdminLayout/AdminLayout";

import { useEffect, useState } from "react";

import useApi from "../../../api/useApi";
import { FaEdit, FaLock, FaPlus, FaTrash } from "react-icons/fa";
import UserForm from "../../../forms/admin/UserForm/UserForm";
import UserPasswordForm from "../../../forms/admin/UserForm/UserPasswordForm";
import useAppStore from "../../../store/useAppStore";

export default function Users() {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isPassModalOpen, setIsPassModalOpen] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const { notificationApi } = useAppStore((state) => state);

  let { api } = useApi(true);

  const getUsersList = async () => {
    try {
      setLoading(true);
      let {
        data: { data },
      } = await api.get("/api/users/list");

      setUsers(data);
      setLoading(false);
    } catch (error) {
      console.log("error ", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    getUsersList();
  }, []);

  const handleDeleteUser = async (row) => {
    try {
      setLoading(true);
      await api.delete("/api/users/" + row._id);
      getUsersList();
      notificationApi["success"]({
        message: "Deleted!",
      });
    } catch (error) {
      console.log("error ", error);
      setLoading(false);
      notificationApi["error"]({
        message: error.message,
      });
    }
  };

  const renderPasswordChangePopup = () => {
    return (
      <Modal
        footer={false}
        open={isPassModalOpen}
        onCancel={() => {
          setIsPassModalOpen(false);
          setSelectedRecord(null);
          getUsersList();
        }}
        onSaveCallback={() => {
          setIsPassModalOpen(false);
          setSelectedRecord(null);
          getUsersList();
        }}
        title={"Password for " + selectedRecord?.email}
      >
        <UserPasswordForm
          record={selectedRecord}
          setIsPassModalOpen={setIsPassModalOpen}
        />
      </Modal>
    );
  };

  const onUserFormFinsh = () => {
    getUsersList();
    setIsModalOpen(false);
  };

  const columns = [
    { title: "Name", key: "name", dataIndex: "name" },
    { title: "Email", key: "email", dataIndex: "email" },
    {
      title: "Company",
      key: "companyName",
      dataIndex: "companyName",
      render: (value, row) => (
        <Flex vertical>
          <div>{value}</div>
          <div>({row.companyWebsite})</div>
        </Flex>
      ),
    },
    { title: "Job Title", key: "jobTitle", dataIndex: "jobTitle" },
    {
      title: "Actions",
      key: "actions",
      dataIndex: "_id",
      render: (value, row) => (
        <Space>
          <Button
            icon={<FaEdit />}
            size="small"
            onClick={() => {
              setSelectedRecord(row);
              setIsModalOpen(true);
            }}
          >
            Edit
          </Button>
          <Popconfirm
            onCancel={() => {}}
            onConfirm={() => {
              handleDeleteUser(row);
            }}
            title="Are you sure?"
            description="Are you sure you want to delete this record?"
          >
            <Button type="primary" icon={<FaTrash />} size="small">
              Delete
            </Button>
          </Popconfirm>
          <Button
            icon={<FaLock />}
            size="small"
            onClick={() => {
              setSelectedRecord(row);
              setIsPassModalOpen(true);
            }}
          >
            Change Password
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <AdminLayout>
      <Card
        title={"Administration - Users"}
        style={{
          margin: 10,
          width: "calc(100% - 20px)",
          minHeight: "calc(100vh - 260px)",
          background: "transparent",
        }}
        extra={[
          <Button
            icon={<FaPlus />}
            key={"add-btn"}
            type="primary"
            onClick={() => {
              setSelectedRecord(null);
              setIsModalOpen(true);
            }}
            size="small"
          >
            Add User
          </Button>,
        ]}
      >
        <div style={{ width: "100%", overflowX: "auto" }}>
          <Table loading={loading} columns={columns} dataSource={users} />
        </div>
      </Card>
      <Modal
        footer={false}
        open={isModalOpen}
        onCancel={() => {
          setIsModalOpen(false);
          setSelectedRecord(null);
          getUsersList();
        }}
        onSaveCallback={() => {
          setIsModalOpen(false);
          setSelectedRecord(null);
          getUsersList();
        }}
        title={selectedRecord ? "Edit User" : "Add New User"}
      >
        <UserForm
          record={selectedRecord}
          setIsModalOpen={setIsModalOpen}
          onUserFormFinsh={onUserFormFinsh}
        />
      </Modal>
      {renderPasswordChangePopup()}
    </AdminLayout>
  );
}
