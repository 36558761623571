import { Button, Form, Input, Space } from "antd";
import { FaSave } from "react-icons/fa";
import useApi from "../../../api/useApi";
import { useEffect, useState } from "react";
import useAppStore from "../../../store/useAppStore";

export default function UserForm({ record, setIsModalOpen, onUserFormFinsh }) {
  let { api } = useApi(true);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const { notificationApi } = useAppStore((state) => state);
  useEffect(() => {
    if (record) {
      form.setFieldsValue({ ...record });
    }
  }, [record, form]);

  const resetFormFields = () => {
    form.setFieldsValue({
      email: "",
      name: "",
      companyName: "",
      companyWebsite: "",
      jobTitle: "",
      password: "",
    });
  };

  const onFinish = async (values) => {
    setLoading(true);
    // Update user
    try {
      if (record) {
        //Edit case
        await api.put("/api/users/", {
          id: record._id,
          ...values,
        });
      } else {
        // Add Case
        await api.post("/api/users/", values);
      }
      setLoading(false);
      onUserFormFinsh();
      resetFormFields();
      notificationApi["success"]({
        message: "Done",
      });
    } catch (error) {
      setLoading(false);
      console.log("error ", error);
      notificationApi["error"]({
        message: error.message,
      });
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <Form
      style={{ width: "100%" }}
      name="profile-form"
      initialValues={record ? record : {}}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
      layout="vertical"
      form={form}
    >
      <Form.Item
        label="Email"
        name="email"
        rules={[
          {
            required: true,
            message: "Please input your email",
            type: "email",
          },
        ]}
      >
        <Input placeholder="Enter your Email" />
      </Form.Item>

      <Form.Item
        label="Name"
        name="name"
        rules={[
          {
            required: true,
            message: "Please input your name",
          },
        ]}
      >
        <Input placeholder="Enter your Name" />
      </Form.Item>

      <Form.Item
        label="Company Name"
        name="companyName"
        rules={[
          {
            required: true,
            message: "Please input your company name",
          },
        ]}
      >
        <Input placeholder="Enter your Company Name" />
      </Form.Item>

      <Form.Item
        label="Job Title"
        name="jobTitle"
        rules={[
          {
            required: true,
            message: "Please input your job title",
          },
        ]}
      >
        <Input placeholder="Enter your Job Title" />
      </Form.Item>
      <Form.Item
        tooltip = "Bridge will examine your website and consider it when creating scenarios."
        label="Company Website"
        name="companyWebsite"
        rules={[
          {
            required: false,
            message: "Please input company Website",
            type: "url",
          },
        ]}
      >
        <Input placeholder="company website" />
      </Form.Item>
      <Form.Item
        label="Password"
        name="password"
        rules={[
          {
            required: record ? false : true,
            message: "Please input password",
          },
        ]}
      >
        <Input placeholder="Password" />
      </Form.Item>

      <Form.Item>
        <Space align="center">
          <Button
            onClick={() => {
              setIsModalOpen(false);
              resetFormFields();
            }}
          >
            Cancel
          </Button>
          <Button icon={<FaSave />} type="primary" htmlType="submit">
            {loading ? "Saving..." : "Save"}
          </Button>
        </Space>
      </Form.Item>
    </Form>
  );
}
