import { Button, Col, Flex, Layout, Row } from "antd";
import useAppStore from "../../store/useAppStore";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import BridgeLogoV3 from "../../assets/img/bridge-logo-v3.png";
import Background from "../../components/Background/Background";

const { Content } = Layout;

export default function AdminLayout({ children, title }) {
  const authenticatedUser = useAppStore((state) => state.authenticatedUser);
  const jwt = useAppStore((state) => state.jwt);
  const navigate = useNavigate();
  useEffect(() => {
    if (
      !authenticatedUser &&
      !jwt &&
      !authenticatedUser.roles.find(
        (role) => role.systemName === "administrator"
      )
    ) {
      navigate("/home");
    }
  }, [jwt, authenticatedUser, navigate]);

  return (
    <Layout style={{ width: "100%", height: "100vh", overflowX: "hidden" }}>
      <Background>
        <Content style={{ width: "100%", height: "100vh" }}>
          <Row align={"top"} justify={"center"} gutter={[10, 10]}>
            <Col span={24}>
              <Flex align="center" justify="start">
                <div>
                  <Button
                    type="link"
                    onClick={() => {
                      navigate("/home");
                    }}
                  >
                    <img
                      style={{ width: 200, marginTop: 10, marginBottom: 20 }}
                      src={BridgeLogoV3}
                      alt="bridge-logo-horizontal"
                    />
                  </Button>
                </div>
              </Flex>
            </Col>
          </Row>
          <Row align={"top"} justify={"left"} gutter={[10, 10]}>
            <Col span={24}>{children}</Col>
          </Row>
        </Content>
      </Background>
    </Layout>
  );
}
