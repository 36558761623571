import React, { useState } from "react";
import { Button, Form, Input } from "antd";
import { useNavigate, useParams } from "react-router-dom";

import useApi from "../../api/useApi";
import useAppStore from "../../store/useAppStore";

export default function ForgetpassForm() {
  let { api } = useApi();
  const token = useParams().token;

  const [loader, setLoader] = useState(false);
  const { notificationApi } = useAppStore((state) => state);
  const navigate = useNavigate();

  const onFinish = async (values) => {
    // reset password
    setLoader(true);
    try {
      let { data = {} } = await api.post("/api/auth/resetpassword", {
        ...values,
        secretKey: token,
      });
      notificationApi["success"]({
        message: data.msg,
      });
      navigate("/login");
    } catch (error) {
      console.log("error ", error);
      notificationApi["error"]({
        message: error?.response?.data?.msg,
      });
    }
    setLoader(false);
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <Form
      style={{ width: "100%" }}
      name="reset-password-form"
      initialValues={{
        remember: false,
      }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
      layout="vertical"
    >
      <Form.Item label="Password" name="password">
        <Input.Password placeholder="Enter your password" />
      </Form.Item>

      <Form.Item label="Confirm password" name="cpassword">
        <Input.Password placeholder="Confirm password" />
      </Form.Item>

      <Form.Item>
        <Button type="primary" htmlType="submit" block loading={loader}>
          CHANGE PASSWORD
        </Button>
      </Form.Item>
      <contextHolder />
    </Form>
  );
}
