import React, { useEffect, useState } from "react";
import { Button, Divider, Flex, Form, Input, Checkbox, Space } from "antd";
import RoleplayCharacter from "./_partials/RoleplayCharacter";

import useApi from "../../api/useApi";
import useAppStore from "../../store/useAppStore";
import { useNavigate } from "react-router-dom";

export default function ScenarioForm({
  record,
  isSystemScenario,
  onSave = () => {},
}) {
  const [chars, setChars] = useState([{}]);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const { authenticatedUser } = useAppStore((state) => state);

  let { api } = useApi(true);
  const navigate = useNavigate();

  const onFinish = async (values) => {
    setLoading(true);
    try {
      if (!record) {
        const {
          data: { data },
        } = await api.post("/api/scenario/", {
          ...values,
          user_id: authenticatedUser._id,
          roleplayCharacters: chars,
          isSystemScenario,
        });

        // add new added scenario to list

        setLoading(false);

        if (isSystemScenario) {
          onSave(data);
        } else {
          navigate("/scenario/summary/" + data._id);
        }
      } else {
        const {
          data: { data },
        } = await api.put("/api/scenario/", {
          ...values,
          user_id: authenticatedUser._id,
          roleplayCharacters: chars,
          _id: record._id,
          isSystemScenario,
        });

        // add new added scenario to list

        setLoading(false);

        if (isSystemScenario) {
          onSave(data);
        } else {
          navigate("/scenario/summary/" + data._id);
        }
      }
    } catch (error) {
      setLoading(false);
      console.log("error ", error);
    }
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const resetFormFields = () => {
    form.setFieldsValue({
      company: "",
      jobTitle: "",
      roleplayCharacters: "",
      saveScenarioAsTemplate: false,
      useProfileJobTitle: false,
      scenarioSpecifics: "",
      title: "",
      useProfileCompany: false,
    });
    setChars([{}]);
  };

  useEffect(() => {
    if (record) {
      const toSet = { ...record };
      record.roleplays.forEach((role, index) => {
        toSet[`roleplayCharacters${index}`] = role.description;
      });

      form.setFieldsValue(toSet);
      setChars(record.roleplays);
    } else {
      resetFormFields();
    }
  }, [record]);

  return (
    <Form
      style={{ width: "100%" }}
      name="login-form"
      initialValues={{
        remember: true,
      }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
      layout="vertical"
      form={form}
    >
      <Form.Item
        label="Title | Skill"
        name="title"
        rules={[
          {
            required: true,
            message: "Please input your title | skill!",
          },
        ]}
      >
        <Input.TextArea
          rows={2}
          placeholder="This is a short description of the skill that you want to practice and the scenario. Eg. Negotiating with Clients."
        />
      </Form.Item>

      <Form.Item
        label={
          <Flex
            style={{ width: "100%" }}
            gap="middle"
            justify="space-between"
            align="center"
          >
            <div>Company</div>
            <div>
              <Form.Item
                style={{ textAlign: "right", margin: 0 }}
                name="useProfileCompany"
                valuePropName="checked"
              >
                <Checkbox
                  onChange={({ target }) => {
                    if (target.checked)
                      form.setFieldsValue({
                        ...form,
                        company: authenticatedUser.companyName,
                      });
                  }}
                >
                  Use Data From My Profile
                </Checkbox>
              </Form.Item>
            </div>
          </Flex>
        }
        name="company"
        rules={[
          {
            required: true,
            message: "Please input your company!",
          },
        ]}
      >
        <Input.TextArea
          rows={4}
          placeholder="Where do you work? What does your company do? The more details the better."
        />
      </Form.Item>

      <Form.Item
        label={
          <Flex
            style={{ width: "100%" }}
            gap="middle"
            justify="space-between"
            align="center"
          >
            <div>Job Title</div>
            <div>
              <Form.Item
                style={{ textAlign: "right", margin: 0 }}
                name="useProfileJobTitle"
                valuePropName="checked"
              >
                <Checkbox
                  onChange={({ target }) => {
                    if (target.checked)
                      form.setFieldsValue({
                        ...form,
                        jobTitle: authenticatedUser.jobTitle,
                      });
                  }}
                >
                  Use Data From My Profile
                </Checkbox>
              </Form.Item>
            </div>
          </Flex>
        }
        name="jobTitle"
        rules={[
          {
            required: true,
            message: "Please input your job title!",
          },
        ]}
      >
        <Input.TextArea
          rows={4}
          placeholder="What do you do at your company? Are you part of a team? Do you manage people, if so, how many? Are Any of your team members important for the role play scenario that you are about to create?"
        />
      </Form.Item>

      <Divider />

      {chars.map((character, index) => {
        return (
          <RoleplayCharacter
            key={"role-char-" + index}
            character={character}
            index={index}
            setChars={setChars}
            chars={chars}
          />
        );
      })}

      {/* <Form.Item>
        <Button
          style={{ borderRadius: "50%", width: 39.75 }}
          size="small"
          type="primary"
          onClick={() => {
            setChars((prev) => [...prev, {}]);
          }}
        >
          <FaPlus />
        </Button>
        &nbsp;Add another Character
      </Form.Item> */}

      <Divider />

      <Form.Item
        label="Scenario Specifics"
        name="scenarioSpecifics"
        rules={[
          {
            required: true,
            message: "Please input scenario specifics",
          },
        ]}
      >
        <Input.TextArea
          rows={2}
          placeholder="Do you have a location in mind? A time of day? Tell us anything that would make this particular interaction unique or interesting for you."
        />
      </Form.Item>

      {!isSystemScenario ? (
        <Form.Item
          name="saveScenarioAsTemplate"
          valuePropName="checked"
          rules={[]}
        >
          <Checkbox>Save Scenario as a Template on my Home Page</Checkbox>
        </Form.Item>
      ) : null}

      <Divider />

      <Form.Item>
        {isSystemScenario ? (
          <Space>
            <Button
              style={{ borderRadius: 22 }}
              size={isSystemScenario ? "middle" : "large"}
              type="primary"
              htmlType="submit"
              block={!isSystemScenario}
              loading={loading}
            >
              {isSystemScenario ? "Save" : loading ? "Processing..." : "Begin"}
            </Button>

            {isSystemScenario ? (
              <Button
                style={{ borderRadius: 22 }}
                size={isSystemScenario ? "middle" : "large"}
                type="default"
                htmlType="button"
                onClick={() => {
                  onSave();
                }}
                loading={loading}
              >
                Cancel
              </Button>
            ) : null}
          </Space>
        ) : (
          <Button
            style={{ borderRadius: 22 }}
            size={isSystemScenario ? "middle" : "large"}
            type="primary"
            htmlType="submit"
            block={!isSystemScenario}
          >
            {isSystemScenario ? "Save" : loading ? "Processing..." : "Begin"}
          </Button>
        )}
      </Form.Item>
    </Form>
  );
}
