import { Button, Col, Divider, Flex, Row, Space, Typography } from "antd";
import PrivateLayout from "../../layouts/PrivateLayout/PrivateLayout";
import CoachingTemplates from "./CoachingTemplates/CoachingTemplates";
import { useNavigate } from "react-router-dom";
import useAppStore from "../../store/useAppStore";
import { FaCogs, FaUser, FaUserLock } from "react-icons/fa";
import { useEffect, useState } from "react";
import useCoachingTemplatesStore from "../../store/useCoachingTemplatesStore";

export default function Home() {
  const navigate = useNavigate();
  const authenticatedUser = useAppStore((state) => state.authenticatedUser);
  const setTemplates = useCoachingTemplatesStore((state) => state.setTemplates);
  const logout = useAppStore((state) => state.logout);

  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    if (
      authenticatedUser &&
      authenticatedUser?.roles?.find(
        (role) => role.systemName === "administrator"
      )
    ) {
      setIsAdmin(true);
    }
  }, [authenticatedUser]);

  return (
    <PrivateLayout>
      <Flex vertical>
        <Flex align="center" justify="center">
          <Space>
            <Button onClick={() => navigate("/profile")} icon={<FaUser />}>
              My Profile
            </Button>
            {isAdmin ? (
              <Button onClick={() => navigate("/admin")} icon={<FaCogs />}>
                Administration
              </Button>
            ) : null}

            <Button
              type="primary"
              onClick={() => {
                setTemplates([]);
                logout();
                navigate("/login");
              }}
              icon={<FaUserLock />}
            >
              Logout
            </Button>
          </Space>
        </Flex>
        <Divider />
        <Row gutter={[10, 10]}>
          <Col span={24} style={{ padding: 20 }}>
            <div style={{ fontSize: 16, fontWeight: "bold" }}>
              <Typography.Title level={5}>Roleplay</Typography.Title>
              <p style={{ fontWeight: "200" }}>
                Practice your sales skills, a difficult conversation with your
                manager, or pitching an investor, etc
              </p>
            </div>

            <Button
              type="primary"
              block
              onClick={() => {
                navigate("/scenario");
              }}
            >
              Create a Roleplay Session
            </Button>
          </Col>
        </Row>
        <Divider style={{ width: "50%" }} />

        <CoachingTemplates />
      </Flex>
    </PrivateLayout>
  );
}
