import { Col, Flex, Layout, Row } from "antd";
import Background from "../../components/Background/Background";
const { Content } = Layout;

export default function PublicLayout({ children }) {
  return (
    <Layout
      style={{
        width: "100%",
        height: "100vh",
        overflowX: "hidden",
        overflowY: "hidden",
      }}
    >
      <Background>
        <Content style={{ width: "100%", height: "100vh" }}>
          <Row
            style={{ height: "100%" }}
            align="middle"
            justify={"center"}
            gutter={[10, 10]}
          >
            <Col span={8} style={{ height: "100%" }}>
              <Flex align="center" justify="center" style={{ height: "100%" }}>
                {children}
              </Flex>
            </Col>
          </Row>
        </Content>
      </Background>
    </Layout>
  );
}
