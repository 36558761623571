import { Button, Flex } from "antd";
import PublicLayout from "../../layouts/PublicLayout/PublicLayout";

import BridgeLogoV3 from "../../assets/img/bridge-logo-v3.png";

// import { FaApple, FaFacebook, FaGoogle } from "react-icons/fa";
import LoginForm from "../../forms/LoginForm/LoginForm";
import { useNavigate } from "react-router-dom";

export default function Login() {
  const navigate = useNavigate();

  return (
    <PublicLayout>
      <Flex
        align="center"
        justify="center"
        vertical
        style={{ height: "100vh" }}
      >
        <div>
          <Button
            type="link"
            onClick={() => {
              navigate("/");
            }}
            block
          >
            <img
              style={{ width: 275, marginBottom: 50 }}
              src={BridgeLogoV3}
              alt="bridge-logo-small"
            />
          </Button>
        </div>
        <div
          style={{
            marginTop: 10,
            fontSize: 16,
            fontWeight: "normal",
            width: 339,
            textAlign: "center",
          }}
        >
          <p>Please Login To Your Account</p>
        </div>

        {/* <Flex justify="center">
          <Space>
            <Button
              type="default"
              size="large"
              style={{
                width: 66,
                height: 66,
                borderRadius: 18,
                fontSize: 23,
              }}
            >
              <FaGoogle />
            </Button>
            <Button
              type="default"
              size="large"
              style={{
                width: 66,
                height: 66,
                borderRadius: 18,
                fontSize: 23,
              }}
              icon={<FaFacebook />}
            ></Button>
            <Button
              type="default"
              size="large"
              style={{
                width: 66,
                height: 66,
                borderRadius: 18,
                fontSize: 23,
              }}
              icon={<FaApple />}
            ></Button>
          </Space>
        </Flex> */}

        {/* <Divider plain style={{ marginTop: 50, marginBottom: 50 }}>
          Or
        </Divider> */}

        <LoginForm />
      </Flex>
    </PublicLayout>
  );
}
