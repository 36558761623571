import useApi from "./useApi";

export default function useSystemScenariosApi() {
  const { api } = useApi(true);

  const fetchAllSystemScenarios = async () => {
    return api.get("/api/scenario/system/get-all");
  };

  const deleteScenario = async (id) => {
    return api.delete(`/api/scenario/system/delete/${id}`);
  };
  return { fetchAllSystemScenarios, deleteScenario };
}
