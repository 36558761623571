import { Card, Col, Flex, Row, Divider } from "antd";
import AdminLayout from "../../layouts/AdminLayout/AdminLayout";
import { FaUsers } from "react-icons/fa";
import { TbMessageChatbot } from "react-icons/tb";

import { useNavigate } from "react-router-dom";

export default function Admin() {
  const navigate = useNavigate();
  return (
    <AdminLayout>
      <Card
        title={"Administration"}
        style={{
          margin: 10,
          width: "calc(100% - 20px)",
          minHeight: "calc(100vh - 260px)",
          background: "transparent",
        }}
      >
        <Row gutter={[20, 20]}>
          <Col xs={24} sm={18} md={12} lg={8} xl={6} xxl={4}>
            <Card
              hoverable
              onClick={() => {
                navigate("/admin/users");
              }}
            >
              <Flex vertical align="center" justify="center">
                <FaUsers style={{ fontSize: 100 }} />
                <Divider />
                <h4>Users</h4>
              </Flex>
            </Card>
          </Col>
          <Col xs={24} sm={18} md={12} lg={8} xl={6} xxl={4}>
            <Card
              hoverable
              onClick={() => {
                navigate("/admin/system/scenarios", { state: "here shate " });
              }}
            >
              <Flex vertical align="center" justify="center">
                <TbMessageChatbot style={{ fontSize: 100 }} />
                <Divider />
                <h4>Scenarios</h4>
              </Flex>
            </Card>
          </Col>
        </Row>
      </Card>
    </AdminLayout>
  );
}
