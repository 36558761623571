import { Flex, Divider } from "antd";

import PrivateLayout from "../../layouts/PrivateLayout/PrivateLayout";
import ProfileForm from "../../forms/ProfileForm/ProfileForm";
import Title from "antd/es/typography/Title";

export default function Profile() {
  return (
    <PrivateLayout>
      <Flex justify="center" align="center" vertical style={{ padding: 20 }}>
        <div style={{ fontSize: 16, fontWeight: "bold" }}>
          <Title level={2}>Profile</Title>
        </div>
        <Divider />
        <ProfileForm />
      </Flex>
    </PrivateLayout>
  );
}
