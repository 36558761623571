import {
  Col,
  Row,
  List,
  Button,
  Flex,
  Input,
  Card,
  Avatar,
  Popconfirm,
  Collapse,
} from "antd";
import useSessionsStore from "../../../store/useCoachingTemplatesStore";
import { FaSearch, FaTrash } from "react-icons/fa";

import { useNavigate } from "react-router-dom";

import { useEffect, useState } from "react";
import useCoachingTemplatesStore from "../../../store/useCoachingTemplatesStore";
import useAppStore from "../../../store/useAppStore";
import useApi from "../../../api/useApi";
import { fetchCoachingTemplates } from "../../../helpers/helpers";

import userIcon from "../../../assets/img/usericon.png";
import useSystemScenariosApi from "../../../api/useSystemScenariosApi";

var fetchTimeout;

export default function CoachingTemplates() {
  const { fetchAllSystemScenarios } = useSystemScenariosApi();
  const templates = useSessionsStore((state) => state.templates);
  const [systemScenarios, setSystemScenarios] = useState([]);
  const [systemScenariosSearched, setSystemScenariosSearched] = useState([]);
  const [templatesSearched, setTemplatesSearched] = useState([]);
  const [searchValue, setSearchValue] = useState("");

  const navigate = useNavigate();
  const { setTemplates } = useCoachingTemplatesStore((state) => state);
  const authenticatedUser = useAppStore((state) => state.authenticatedUser);
  let { api } = useApi(true);

  const [loading, setLoading] = useState(false);

  const fetchScenarios = async () => {
    try {
      setLoading(true);
      let list = await fetchCoachingTemplates(authenticatedUser._id, api);
      const {
        data: { list: sysScenarios },
      } = await fetchAllSystemScenarios();
      setTemplates(list);
      setSystemScenarios(sysScenarios);
    } catch (err) {
      console.log({ err }, err.message);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const handleScenarioHide = async (values) => {
    setLoading(true);
    const { data } = await api.get("/api/scenario/hide/" + values._id);
    if (data.status) {
      fetchScenarios();
    }
  };

  useEffect(() => {
    if (fetchTimeout) {
      clearTimeout(fetchTimeout);
    }

    fetchTimeout = setTimeout(() => {
      if (authenticatedUser?._id) fetchScenarios();
    }, 500);
  }, [authenticatedUser]);

  const handleSearch = ({ target }) => {
    if (target.value.length > 0) {
      let sysScen = systemScenarios.filter((e) =>
        e.title.toLowerCase().includes(target.value.toLowerCase())
      );
      let userScen = templates.filter((e) =>
        e.title.toLowerCase().includes(target.value.toLowerCase())
      );
      setSystemScenariosSearched(sysScen);
      setTemplatesSearched(userScen);
      setSearchValue(target.value);
    } else {
      setSearchValue("");
    }
  };

  const renderTemplatesList = (templates) => {
    return (
      <List
        loading={loading}
        itemLayout="horizontal"
        dataSource={templates}
        renderItem={(item, index) => {
          return (
            <Card
              styles={{ body: { padding: "10px 20px" } }}
              style={{ margin: 20, borderRadius: 15 }}
            >
              <List.Item
                actions={
                  item.isSystemScenario
                    ? [
                        <Button
                          key={`btn-action-go-${item._id}`}
                          type="primary"
                          size="small"
                          onClick={() => {
                            navigate("/scenario/chat/" + item._id);
                          }}
                          shape="round"
                        >
                          Go
                        </Button>,
                      ]
                    : [
                        <Button
                          key={`btn-action-go-${item._id}`}
                          type="primary"
                          size="small"
                          onClick={() => {
                            navigate("/scenario/" + item._id);
                          }}
                          shape="round"
                        >
                          Go
                        </Button>,
                        <Popconfirm
                          key={`btn-action-del-${item._id}`}
                          onCancel={() => {}}
                          onConfirm={() => {
                            handleScenarioHide(item);
                          }}
                          okText="Yes"
                          cancelText="No"
                          title="Are you Sure?"
                          description="Are you sure you want to remove this scenario?"
                        >
                          <Button
                            shape="circle"
                            icon={<FaTrash />}
                            size="small"
                          />
                        </Popconfirm>,
                      ]
                }
              >
                <List.Item.Meta
                  avatar={
                    item.roleplays[0] && item.roleplays[0].photoUrl ? (
                      <Avatar src={item.roleplays[0].photoUrl} />
                    ) : (
                      <Avatar src={userIcon} alt="default-user-img" />
                    )
                  }
                  title={item.title}
                />
              </List.Item>
            </Card>
          );
        }}
      />
    );
  };

  const items = [
    {
      key: "1",
      label: "Book Inspired Experiences",
      children: renderTemplatesList(
        searchValue ? systemScenariosSearched : systemScenarios
      ),
    },
    {
      key: "2",
      label: "User Created Scenarios",
      children: renderTemplatesList(
        searchValue ? templatesSearched : templates
      ),
    },
  ];

  return (
    <Row gutter={[10, 10]}>
      <Col span={24}>
        <Flex vertical justify="center">
          <div style={{ textAlign: "center", marginTop: 30, marginBottom: 30 }}>
            <Input
              prefix={<FaSearch style={{ color: "#D71E1E" }} />}
              style={{ width: "80%" }}
              placeholder="Search"
              onChange={handleSearch}
              value={searchValue}
            />
          </div>
          <Collapse
            bordered={false}
            items={items}
            defaultActiveKey={["1", "2"]}
          />
        </Flex>
      </Col>
    </Row>
  );
}
