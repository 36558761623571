import { create } from "zustand";
import { mountStoreDevtool } from "simple-zustand-devtools";

const useCoachingTemplatesStore = create((set) => ({
  name: "Coaching Templates",
  templates: [],
  setTemplates: (templates) =>
    set((state) => {
      return { templates: templates };
    }),
}));

mountStoreDevtool("useCoachingTemplatesStore", useCoachingTemplatesStore);
export default useCoachingTemplatesStore;
