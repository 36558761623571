import { Button, Form, Input, Space } from "antd";
import { FaSave } from "react-icons/fa";
import useApi from "../../../api/useApi";
import { useEffect, useState } from "react";
import useAppStore from "../../../store/useAppStore";

export default function UserPasswordForm({ record, setIsPassModalOpen }) {
  let { api } = useApi(true);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const { notificationApi } = useAppStore((state) => state);

  useEffect(() => {
    if (record) {
      form.setFieldsValue({ ...record, password: "" });
    }
  }, [record, form]);

  const onFinish = async (values) => {
    setLoading(true);
    try {
      if (record) {
        //update password
        await api.post("/api/users/password", {
          id: record._id,
          ...values,
        });
      }
      setLoading(false);
      setIsPassModalOpen(false);
      notificationApi["success"]({
        message: "Changed!",
      });
    } catch (error) {
      console.log("error ", error);
      notificationApi["error"]({
        message: error.message,
      });
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <Form
      style={{ width: "100%" }}
      name="profile-form"
      initialValues={record ? record : {}}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
      layout="vertical"
      form={form}
    >
      <Form.Item
        label="Password"
        name="password"
        rules={[
          {
            required: true,
            message: "Please input password!",
          },
        ]}
      >
        <Input placeholder="Enter Password" />
      </Form.Item>

      <Form.Item>
        <Space align="center">
          <Button
            onClick={() => {
              setIsPassModalOpen(false);
            }}
          >
            Cancel
          </Button>
          <Button icon={<FaSave />} type="primary" htmlType="submit">
            {loading ? "Saving..." : "Save"}
          </Button>
        </Space>
      </Form.Item>
    </Form>
  );
}
