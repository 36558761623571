export function setLocalStorage(key, value) {
  typeof value === "string"
    ? localStorage.setItem(key, value)
    : localStorage.setItem(key, JSON.stringify(value));
}

export function getLocalStorage(key, parse = false) {
  return localStorage && localStorage.getItem(key)
    ? parse
      ? JSON.parse(localStorage.getItem(key))
      : localStorage.getItem(key)
    : null;
}

export const fetchCoachingTemplates = async (id, api) => {
  try {
    const { data } = await api.get("/api/scenario/all/" + id);
    return data.list;
  } catch (error) {
    return [];
  }
};
