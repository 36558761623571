import { create } from "zustand";
import { mountStoreDevtool } from "simple-zustand-devtools";
import { getLocalStorage, setLocalStorage } from "../helpers/helpers";

// read local data
const getUserLocalData = () => {
  let user = getLocalStorage("user", true);
  let token = getLocalStorage("token", false);
  return { user, token };
};

const useAppStore = create((set) => ({
  name: "Bridge",
  authenticatedUser: getUserLocalData().user,
  jwt: getUserLocalData().token,
  setAuthenticatedUser: (user) =>
    set((state) => {
      setLocalStorage("user", user);
      return { authenticatedUser: user };
    }),
  setJwt: (jwt) =>
    set((state) => {
      setLocalStorage("token", jwt);
      return { jwt: jwt };
    }),
  logout: (user) =>
    set((state) => {
      localStorage.clear();
      state.notificationApi['success']({
        message: "Logout Success",
        description: "",
      });
      return { authenticatedUser: null, jwt: null };
    }),
    notificationApi : null,
  setNotification: (api) =>
    set((state) => {
      return { notificationApi : api };
    }),
}));

mountStoreDevtool("Store", useAppStore);
export default useAppStore;
